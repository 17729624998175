<template>
  <router-link v-if="!editable" :to="{ name: 'checklist', params: { checklistId: checklist.id, menuName: checklist.name }}">
    <div class="checklist">
      <h2>{{ checklist.name }}</h2>
      <Arrow></Arrow>
    </div>
  </router-link>
  <router-link v-else :to="{ name: 'editChecklist', params: { checklistId: checklist.id, menuName: checklist.name }}">
    <div class="checklist">
      <h2>{{ checklist.name }}</h2>
      <span @click.prevent="deleteChecklist()"><fa icon="trash" /></span>
    </div>
  </router-link>
  <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
</template>
<script>
import Arrow from '@/assets/arrowRight.svg';
import ChecklistService from '@/services/ChecklistService';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog'

export default {
  name: 'CheckList',
  components: {
    Arrow,
    ConfirmationDialog
  },  
  props: {
    checklist: Object,
    editable: Boolean,
  },
  emits: ['deleteChecklist'],
  methods: {
    async deleteChecklist() {
      const confirmDelete = await this.$refs.confirmationDialog.show({
        title: 'Slett sjekkliste',
        message: 'Er du sikker på du vil slette denne sjekklisten og alle tilhørende sjekkpunkter?',
        okButton: 'Slett sjekkliste',
      })
      if (confirmDelete) {
        await ChecklistService.deleteChecklist(this.checklist.id);
        this.$emit('deleteChecklist', this.checklist.id);

        this.$toast.success(`Sjekkliste slettet`);
      } else {
        this.$toast.info(`Sletting avbrutt`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.checklist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  @media (min-width: $breakpoint-mobile) {
    padding: 2rem;
  }
  font-weight: 500;
  cursor: pointer;
  h2 {
    margin-bottom: 0;
    font-weight: 500;
    color: black;
  }
  span {
    background-color: #FFE3EA;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .fa-trash {
      color: #D64568;
    }
  }

}
</style>
